<!-- ========== MAIN CONTENT ========== -->
<main id="content">
  <!-- ========== HERO ========== -->
  <div class="hero-block hero-v1 bg-img-hero-bottom gradient-overlay-half-black-gradient z-index-2 bus-search-page">
    
    <div class="container bus-search">
       
      <div class="d-flex my-2" *ngIf="modify">
        <div class="w-100 bg-light flex-fill">
          <form class="js-validate" [formGroup]="busForm" (ngSubmit)="busFormSubmit()">
            <div class="row d-block nav-select d-lg-flex mb-lg-3 px-2 px-lg-3 align-items-end">
              <div class="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-4 mb-lg-0">
                <!-- <span class="d-block text-gray-1 font-weight-normal text-left mb-0">From </span> -->
                <!--<select class="form-control" formControlName="SourceId"
                    [ngClass]="{ 'is-invalid': (busForm.get('SourceId').value && (busForm.get('SourceId').value === busForm.get('DestinationId').value)) }">
                    <option *ngFor="let bus of busList" [value]="bus.CityId">{{bus.CityName}}</option>
                </select>-->
                <location-picker (onSelectLocationChange)="sourceLocation($event)" [placeHolder]="travelFrom"></location-picker>

              </div>

              <div class="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-4 mb-lg-0">
                <!-- <span class="d-block text-gray-1 font-weight-normal text-left mb-0">To</span> -->
                <!--<select class="form-control" formControlName="DestinationId"
                    [ngClass]="{ 'is-invalid': (busForm.get('DestinationId').value && (busForm.get('SourceId').value === busForm.get('DestinationId').value)) }">
                    <option *ngFor="let bus of busList" [value]="bus.CityId">{{bus.CityName}}</option>
                </select>-->
                <location-picker (onSelectLocationChange)="destLocation($event)" [placeHolder]="travelTo"></location-picker>

              </div>


              <div class="col-sm-12 col-lg-2dot6 col-xl-2gdot5 mb-2 mb-lg-0 ">
                <!-- <span class="d-block text-gray-1 text-left font-weight-normal mb-0">Travel Date</span> -->
                <div class="border-bottom border-width-2 border-color-1">
                  <div id="datepickerWrapperFromOne" class="u-datepicker input-group">
                    <div class="input-group-prepend">
                      <span class="d-flex align-items-center mr-2">
                        <img src="https://static.abhibus.com/assets/img/date.png" alt="destination pointer">
                      </span>
                    </div>
                    <input type='datetime-local' formControlName="JourneyDate" class="form-control datepicker" data-date-format="mm/dd/yyyy">

                  </div>
                </div>
              </div>
              <div class="col">
                <button type="submit" [disabled]="busForm.invalid || (busForm.get('SourceId').value === busForm.get('DestinationId').value)"
                        class="btn btn-primary w-100 border-radius-3 mb-xl-0 mb-lg-1 transition-3d-hover">
                  <i class="flaticon-magnifying-glass font-size-20 mr-2"></i>Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

     
      <div class="row">
        <div class="w-100 d-flex my-2 bg-gray-33 card-body" *ngIf="!modify">
          <div class="col">
            
            <!--<div *ngFor="let bus of busList">
                <div *ngIf="bus.CityId == searchParams.SourceId">{{bus.CityName}}</div>
            </div>-->
            <location-picker (onSelectLocationChange)="sourceLocation($event)" #source [placeHolder]="travelFrom"></location-picker>
            <div *ngIf="busformSubmitted && !sourceIdValid" class="invalid-feedback  d-block">
              <div *ngIf="!sourceIdValid">Source is required</div>
            </div>
          </div>
          <div style="cursor: pointer;" (click)="exchageSourceAndDestination()">
              <div class="twowaychang-new">
								  <a class="twowaychang">
									<img src="https://static.abhibus.com/assets/img/twoway.png" alt="two way" width="30px" style="position:relative; z-index:10">
								</a>
							</div>
          </div>
          <div class="col">
            
            <!--<div *ngFor="let bus of busList">
                <div *ngIf="bus.CityId == searchParams.DestinationId">{{bus.CityName}}</div>
            </div>-->
            <location-picker (onSelectLocationChange)="destLocation($event)" #destination [placeHolder]="travelTo"></location-picker>
            <div *ngIf="busformSubmitted && !destIdValid" class="invalid-feedback  d-block">
              <div *ngIf="!destIdValid">Destination is required</div>
            </div>
          </div>
          <div class="col">
           
            <!--<div>{{searchParams.JourneyDate | date: 'dd-MM-yyyy'}}</div>-->
            <mat-form-field>
              <input matInput [matDatepicker]="picker"  [(ngModel)]="searchParams.JourneyDate" (ngModelChange)="searchParams.JourneyDate = $event" [min]="today"
                     placeholder="Choose a date" 
                     (focus)="_openCalendar(picker)" 
                     (click)="_openCalendar(picker)" 
                     #elementToFocus readonly>
              <mat-datepicker #picker="matDatepicker" ></mat-datepicker>
            </mat-form-field>
            <!-- <input type='date' [(ngModel)]="searchParams.JourneyDate" (ngModelChange)="searchParams.JourneyDate = $event" class="form-control datepicker"
                   data-date-format="mm/dd/yyyy"> -->
                   <div *ngIf="busformSubmitted && !JourneyDateValid" class="invalid-feedback  d-block">
                    <div *ngIf="!JourneyDateValid">Date is required</div>
                  </div>
          </div>
          <div class="col">
            <!--<button type="button" (click)="(modify = !modify)"
            class="btn btn-outline-primary">Modify</button>-->
            <div class="searc-btn">
              <button type="button" (click)="busFormSubmit()" class="btn btn-outline-primary">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
   
      <div class="row mb-8">
        <div class="col-lg-12 col-xl-12 mt-xl-1 p-0" *ngIf="this.busesSearchResponse.AvailableBuses?.length">
          <app-search (groupFilters)="searchFilter($event)" [filterInputList]="finalFilterInputList"></app-search>
        </div>

        <div class="col-xl-12  mt-xl-1">
          <!-- Slick Tab carousel -->
          <div class="u-slick__tab" *ngIf="filteredAvailableBuses.length === 0">
            <p style="color: #FFF;">{{busesSearchResponse?.Status?.message}}</p>
          </div>
          <div *ngIf="filteredAvailableBuses && searchBus">
            <div class="row search-labels">
              <div class="col-sm-3 text-left flex-center-between" style="padding-right: 0px;">
                <span>{{filteredAvailableBuses.length}} Buses Found</span>
                <!-- <span>Sorty By:</span> -->
              </div>
              <div class="col-sm-7 text-left" *ngIf="filteredAvailableBuses.length>0">
                <div class="row">
                  <div class="col">Departure</div>
                  <!-- <div class="col">Duration</div> -->
                  <div class="col">Arrival</div>
                  <!-- <div class="col">Ratings</div> -->
                  <div class="col">Fare</div>
                </div>
              </div>
              <div class="col-sm-2 text-right" *ngIf="filteredAvailableBuses.length>0">
                <div>Seats Available</div>
              </div>
            </div>
            <div *ngFor="let avlbus of filteredAvailableBuses;let i = index">
              <div class="w-100 flex-fill parent-container search-data" [ngClass]="avlbus.isShow?'active':''">
                <div class="row p-2">
                  <div class="col-sm-3 text-left">
                    {{avlbus.OperatorName}}
                    ({{avlbus.BusType}})
                  </div>
                  
                  <div class="col-sm-7 text-left">
                    <div class="row">
                      <!-- <div class="col"></div> -->
                      <div class="col">{{avlbus.DepartureTime}}</div>
                      <!-- <div class="col">{{duration(avlbus.DepartureTime,avlbus.ArrivalTime)}}</div> -->
                      <div class="col">{{avlbus.ArrivalTime}}</div>
                      <div class="col ellipsis">{{avlbus.OperatorBaseFare}}</div>
                    </div>
                  </div>
                  <div class="col-sm-2 text-right">
                    <div>{{avlbus.AvailableSeats}} Seats</div>
                  </div>
                </div>
                <div class="bottom-list">
                  <div class="view-seat-btn" *ngIf="!avlbus.isShow">
                    <button type="button" class="btn btn-outline-primary btn-sm" (click)="callShowSeatsAPI(avlbus,i);">View Seats</button>
                  </div>
                  <div class="view-seat-btn" *ngIf="avlbus.isShow">
                    <button type="button" class="btn btn-outline-primary btn-sm" (click)="showSeats(i)">Hide Seats</button>
                  </div>
                  <div class="show-layer">
                    <div class="child-container hover-details ">
                      <div class="d-flex list-hover">
                        <!-- <div class="p-2 w-30"></div> -->
                        <div class="w-100 flex-center-between hover-options">
                          <!-- <div (click)="showBoarding(i)" class="btn-outline-primary anc">Boarding & Dropping Points</div> -->
                          <div (click)="showBookingPolicies(i)" class="btn-outline-primary anc">Cancellation Policy</div>
                          <!-- <div (click)="showRestStop(i)">Rest Stop</div> -->
                          <!-- <div (click)="avlbus.isShow = !avlbus.isShow">4</div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="show-layer">
                      <div class="view-seat-btn" *ngIf="avlbus.isShow">
                          <button type="button" class="btn btn-outline-primary btn-sm" (click)="showSeats(i)">Hide Seats</button>
                      </div>
                      <div class="d-flex list-hover">
                          <div class="w-100 flex-center-between hover-options">
                              <div (click)="showBoarding(i)">Boarding & Dropping Points</div>
                              <div (click)="showBookingPolicies(i)">Booking Policies</div>
                          </div>
                      </div>
                  </div> -->
                </div>
                <div class="bottom-list">
                  <div *ngIf="avlbus.isShow" class="child-container hover-details">
                    <div *ngIf="avlbus.showBoarding" class="p-2 border-top">
                      <div class="row">
                        <div class="col">
                          <p style="text-transform: uppercase;">Boarding Points</p>
                          <div class="droplist-time w-100">
                            <div class="d-flex justify-content-between"  *ngFor="let boarding of avlbus.BoardingPoints">
                              <div style="font-size: 10px;" class="time-location">
                                <span style="font-weight: bolder;">{{boarding.Time}}</span>
                                <span>{{boarding.Location}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <p style="text-transform: uppercase;">Dropping Points</p>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="avlbus.showBookingPolicies" class="p-2 border-top">
                      <div class="Table"> 

                        <div class="Heading">
                          <div class="Cell">
                              <p>Cut Off Time:</p>
                          </div>
                          <div class="Cell">
                              <p> (%):</p>
                          </div>                            
                        </div>
                        
                          <div class="Row"  *ngFor="let can of avlbus.cancellationPolicy">
                              <div class="Cell">
                                  <p>{{can.cutoffTime}}</p>                                  
                              </div>
                              <div class="Cell">
                                  <p>{{can.refundInPercentage}}</p>                                  
                              </div>                 
                          </div>                       
                        
                      </div>
                      
                  

                    </div>
                    <div class="row bus-payment-details border-top" *ngIf="seatLayoutLower && seatLayoutLower.length>0">
                      <div class="col-md-8 col-sm-8 pr-0">
                        <div class="w-100 row" *ngIf="avlbus.showSeats">
                          <seat-layout [seats]="seatLayoutLower" [zIndex]="(seatLayoutUpper && seatLayoutUpper.length>0)?0:2" (onSelectSeat)="selectedSeats($event,avlbus,0)"></seat-layout>
                          <seat-layout [seats]="seatLayoutUpper" [zIndex]="1" *ngIf="seatLayoutUpper && seatLayoutUpper.length>0" (onSelectSeat)="selectedSeats($event,avlbus,1)"></seat-layout>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-4 p-0">
                        <div *ngIf="avlbus.showSeats">

                          <div class="right" >
                            <form name="frmSeat685406721" id="frmSeat685406721" class="user-payment">

                              <h3>Onward Journey </h3>
                              <p>
                                From
                                <strong>{{this.sourceCity}}</strong> To
                                <strong>{{this.destCity}}</strong>
                                <br> on
                                <strong>{{searchParams.JourneyDate |  date :  "dd MMM, yy"}}</strong>
                              </p>

                              <div class="payment-item">
                                <div class="clearfix">
                                  <p class="left">Seats Selected </p>
                                  <p class="right">
                                    <span id="seatnos">{{getSelectedSeats(avlbus)}}</span>
                                  </p>
                                </div>
                                <div class="clearfix">
                                  <!-- <p class="left">Basic Fare </p>
                                  <p class="right">
                                    <span id="ticketfare">
                                      <i class="fa fa-inr" aria-hidden="true"></i>
                                      {{avlbus.basicFare}}
                                    </span>&nbsp;&nbsp;&nbsp;
                                    <span id="ticketnew"></span>
                                  </p> -->
                                </div>
                                <!-- <div class="clearfix">
                                  <p class="left">GST </p>
                                  <p class="right">
                                    <span id="servicetaxamt">
                                      <i class="fa fa-inr" aria-hidden="true"></i>
                                      {{avlbus.gst}}
                                    </span>&nbsp;&nbsp;&nbsp;
                                    <span id="servicetaxamtnew"></span>
                                  </p>
                                </div> -->
                                <!-- <div class="clearfix">
                                  <p class="left">Operator Service Charge + EVA </p>
                                  <p class="right">
                                    <span id="servicechargeamt">
                                      <i class="fa fa-inr" aria-hidden="true"></i>
                                      {{avlbus.totalCharges}}
                                    </span>&nbsp;&nbsp;&nbsp;
                                    <span id="servicechargeamtnew"></span>
                                  </p>
                                </div> -->

                                <div class="clearfix">
                                  <p class="left">Amount </p>
                                  <p class="right">
                                    <span id="totalfare">
                                      <i class="fa fa-inr" aria-hidden="true"></i>
                                      {{avlbus.amount}}
                                    </span>&nbsp;&nbsp;&nbsp;
                                    <span id="totalfarenew"></span>&nbsp;&nbsp;&nbsp;
                                    <span id="totaldiscount"></span>
                                  </p>
                                </div>



                                <div class="clearfix">
                                  <p for="id_select" class="left">Boarding Point </p>
                                  <span class="right">
                                    <select class="dropdown_custom" name="boardingpoint_id" id="boardingpoint_id" style="width:160px ! important;" [(ngModel)]='avlbus.boarding'>
                                      <option value="">Boarding Point </option>
                                      <option *ngFor="let boarding of avlbus.BoardingPoints" [value]="boarding.Id">
                                        {{boarding.Location |slice:0:20}} - {{boarding.Time}}
                                      </option>
                                    </select>
                                  </span>
                                </div>



                                <div class="redfnt" id="txtBrd" style="display:none"></div>
                                <div class="red-landmark" id="bpDesc1685406721" style="display:none"></div>
                                <div class="clearfix">
                                  <p for="id_select" class="left">Dropping Point</p>
                                  <span class="right">
                                    <select class="dropdown_custom" name="droppingpoint_id" id="droppingpoint_id" style="width:160px ! important;" [(ngModel)]="avlbus.destination">
                                      <option value="">Dropping Point</option>
                                      <option [value]="this.searchParams.DestinationId" *ngIf="avlbus.DroppingPoints.length==0">
                                        {{destCity}} - {{avlbus.ArrivalTime}}
                                      </option>
                                      <option *ngFor="let destination of avlbus.DroppingPoints" [value]="destination.Id">
                                        {{destination.Location |slice:0:20}} - {{destination.Time}}
                                      </option>
                                    </select>
                                  </span>
                                </div>
                                <div class="redfnt" id="txtDropping" style="display:none">
                                </div>
                              </div>
                              <div class="paymnt">

                                <!--<input name="submit" id="btnDisable1" type="button" value="Continue to Payment " class="button disable shadow" style="display: inline-block;">-->
                                <input name="submit" id="btnEnable1" type="button" value="Continue to Payment " class="button shadow" (click)="avlbus.payment=false;onChangeSeats(avlbus.selectedSeats);"
                                       data-toggle="modal" data-target="#myModal2">
                              </div>
                            </form>
                          </div>
                        </div>
                        <!-- Passenger details popup -->
                       
                        <!-- Passenger details -->
                        <!-- <div class="right" *ngIf="!avlbus.payment">
      <form name="frmSeat685406721" id="frmSeat685406721">

          <h3>Passenger Information</h3>
          <p>From <strong>{{this.sourceCity}}</strong> To
              <strong>{{this.destCity}}</strong> <br> on
              <strong>{{this.searchParams.JourneyDate}}</strong></p>

          <table width="100%" border="0" cellspacing="0" cellpadding="0"
              class="tblpsgr">
              <tbody>
                  <tr *ngFor="let seat of avlbus.selectedSeats">
                      <td>
                          Name :<input type="text" name="{{seat.Id}}_name"
                              [value]="seat.Name"
                              (input)="seat.Name = $event.target.value">
                      </td>
                      <td>
                          Age :<input type="text" name="{{seat.Id}}_age"
                              [value]="seat.Age"
                              (input)="seat.Age= $event.target.value">
                      </td>
                      <td>
                          <label for="male">Male</label>
                          <input type="radio" name="{{seat.Id}}_radio"
                              id="male" value="male"
                              (input)="seat.Gender= $event.target.value">
                      </td>
                      <td>
                          <label for="female">Female</label>
                          <input type="radio" name="{{seat.Id}}_radio"
                              id="female" value="female"
                              (input)="seat.Gender= $event.target.value">
                      </td>
                  </tr>
                  <tr>
                      <td>
                          Email :<input type="text"
                              name="{{avlbus.TracedId}}_email"
                              [value]="avlbus.Email"
                              (input)="avlbus.Email= $event.target.value">
                      </td>
                      <td>
                          Contact:<input type="text"
                              name="{{avlbus.TracedId}}_contact"
                              [value]="avlbus.Contact"
                              (input)="avlbus.Contact= $event.target.value">
                      </td>

                  </tr>
                  <tr>
                      <td>
                          <input name="submit" id="btnEnable1" type="button"
                              value="Back to seat selection"
                              class="button shadow"
                              (click)="avlbus.payment=true;avlbus.showSeats=true;">
                      </td>
                      <td>
                          <input name="submit" id="btnEnable1" type="button"
                              value="Proceed to payment" class="button shadow"
                              (click)="blockTicket(avlbus)">
                      </td>

                  </tr>
              </tbody>
          </table>
      </form>
  </div> -->
                      </div>
                  <div  class="col-md-12 col-sm-8 pr-0 passenger-payment-details"  >  
                     <form [formGroup]="busRegistrationForm" (ngSubmit)="submitBusRegistrationForm(avlbus,t.controls)">
                        <div *ngIf="!avlbus.payment">
                          <div></div>
                          <div >
                            <div class="pop-header">
                              <!-- hide popup onclick -->
                              <!-- <button (click)="avlbus.payment=true;avlbus.showSeats=true;" title="Back">
                                <span class="flaticon-right-arrow"></span>
                              </button> -->
                              <h3>Passenger Information</h3>
                            </div>
                           
                              <p>
                                From
                                <strong>{{this.sourceCity}}</strong> To
                                <strong>{{this.destCity}}</strong>
                                <br> on
                                <strong>{{this.searchParams.JourneyDate |  date :  "dd MMM, yy"}}</strong>
                              </p>
                              <div class="dynamic-body">
                                <div class="passenger-info" *ngFor="let seat of t.controls; let i = index">
                                  
                                  <div [formGroup]="seat">
                                    <div style="padding-bottom: 10px;padding-left: 10px;"><span>Seat no - {{seat.controls.Id.value}}</span></div>
                                  <div class="input_in">
                                    <input type="text" class="form-control" formControlName="passengerName">
                                    <span class="floating_label">Passenger Name*</span>
                                    <div *ngIf="busRegistrationFormSubmitted && seat.controls.passengerName.errors" class="invalid-feedback  d-block">
                                      <div *ngIf="seat.controls.passengerName.errors?.required">Passenger Name is required</div>
                                  </div>
                                    <!-- <span class="error_msg">Enter product Publisher .</span> -->
                                  </div>
                                  <div class="inputs-two">
                                    <div class="input_in">
                                      <input type="number" formControlName="passengerAge" class="form-control">
                                      <span class="floating_label">Age</span>
                                      <div *ngIf="busRegistrationFormSubmitted && seat.controls.passengerAge.errors" class="invalid-feedback  d-block">
                                        <div *ngIf="seat.controls.passengerAge.errors?.required">Passenger Age is required</div>
                                      <div class="invalid-feedback d-block" *ngIf="seat.controls.passengerAge.errors?.age">
                                        <sup>*</sup>Age should  be between 1 and 99
                                      </div>
                                    </div>
                                    <!-- <span class="error_msg">Enter product Publisher .</span> -->
                                    </div>
                                    <div class="gender-select">
                                      <div class="gender">
                                        <label for="male">Male</label>
                                        <input type="radio" formControlName="passengerGender" checked  value="M" >
                                      </div>
                                      <div class="gender">
                                        <label for="female">Female</label>
                                        <input type="radio" formControlName="passengerGender"  value="F" >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                                <div class="input_in">
                                  <input type="text" class="form-control" formControlName="passengerEmail" required="" name="{{avlbus.TracedId}}_email" [value]="avlbus.Email" (input)="avlbus.Email= $event.target.value">
                                  <span class="floating_label">Passenger Email*</span>
                                  <div *ngIf="busRegistrationFormSubmitted && f.passengerEmail.errors" class="invalid-feedback  d-block">
                                     <div *ngIf="f.passengerEmail.errors?.required">Passenger Email is required</div> 
                                    <div *ngIf="f.passengerEmail.errors?.email">Passenger Email is invalid</div>
                                </div>
                                  <!-- <span class="error_msg">Enter product Publisher .</span> -->
                                </div>
                                <div class="input_in">
                                  <input type="number" class="form-control" formControlName="passengerContact" required="" name="{{avlbus.TracedId}}_contact" [value]="avlbus.Contact" (input)="avlbus.Contact= $event.target.value">
                                  <span class="floating_label">Passenger contact*</span>
                                  <div *ngIf="busRegistrationFormSubmitted && f.passengerContact.errors" class="invalid-feedback  d-block">
                                    <div *ngIf="f.passengerContact.errors?.required">Passenger contact is required</div>
                                </div>
                                  <!-- <span class="error_msg">Enter product Publisher .</span> -->
                                </div>
                                <div class="input_in" style="display: none;">
                                  <input type="text" class="form-control" formControlName="passengerAddress" name="{{avlbus.TracedId}}_address" [value]="avlbus.Address" (input)="avlbus.Address= $event.target.value">
                                  <span class="floating_label">Passenger Address*</span>
                                  <div *ngIf="busRegistrationFormSubmitted && f.passengerAddress.errors" class="invalid-feedback  d-block">
                                    <div *ngIf="f.passengerAddress.errors?.required">Passenger Address is required</div>
                                </div>
                                  <!-- <span class="error_msg">Enter product Publisher .</span> -->
                                </div>
                              </div>

                              <div class="btns footer">
                                <input name="button" id="btnEnable1" type="button" value="Back to seat selection" class="button shadow" (click)="clearFields();avlbus.payment=true;avlbus.showSeats=true;"> 
                                <input name="submit" id="btnEnable2" type="submit" value="Proceed to payment" class="button shadow">
                              </div>
                          </div>
                        </div>
                        </form>
                  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="u-slick__tab" style="display: none;visibility: hidden;" *ngIf="busesSearchResponse?.AvailableBuses.length > 0">
            <!-- Nav Links -->
            <div class="mb-5">
              <ul class="nav flex-nowrap border border-radius-3 tab-nav align-items-center py-2 px-0" role="tablist">
                <li class="nav-item d-flex align-items-center flex-shrink-0 flex-xl-shrink-1">
                  <a href="#" class="nav-link font-weight-normal text-gray-1 text-lh-1dot6 py-1 px-4 px-wd-5 font-weight-normal font-size-15 ">
                    Recommended
                  </a>
                </li>
                <li class="nav-item d-flex align-items-center flex-shrink-0 flex-xl-shrink-1 border-left">
                  <select class="js-select selectpicker dropdown-select bootstrap-select__custom-nav w-auto" data-style="btn-sm py-1 px-4 px-wd-5 font-weight-normal font-size-15  text-gray-1 d-flex align-items-center">
                    <option value="one" selected>Price</option>
                    <option value="two">Two</option>
                    <option value="three">Three</option>
                    <option value="four">Four</option>
                  </select>
                </li>
                <li class="nav-item d-flex align-items-center flex-shrink-0 flex-xl-shrink-1 border-left">
                  <select class="js-select selectpicker dropdown-select bootstrap-select__custom-nav w-auto" data-style="btn-sm py-1 px-4 px-wd-5 font-weight-normal font-size-15  text-gray-1 d-flex align-items-center">
                    <option value="one" selected>Stars</option>
                    <option value="two">Two</option>
                    <option value="three">Three</option>
                    <option value="four">Four</option>
                  </select>
                </li>
                <li class="nav-item d-flex align-items-center flex-shrink-0 flex-xl-shrink-1 border-left">
                  <a href="#" class="nav-link font-weight-normal text-gray-1 text-lh-1dot6 py-1 px-4 px-wd-5 font-weight-normal font-size-15 ">
                    Guest Rating
                  </a>
                </li>
                <li class="nav-item d-flex align-items-center flex-shrink-0 flex-xl-shrink-1 border-left">
                  <a href="#" class="nav-link font-weight-normal text-gray-1 text-lh-1dot6 py-1 px-4 px-wd-5 font-weight-normal font-size-15 ">
                    Distance
                  </a>
                </li>
                <li class="nav-item d-flex align-items-center flex-shrink-0 flex-xl-shrink-1 border-left">
                  <a href="#" class="nav-link font-weight-normal text-gray-1 text-lh-1dot6 py-1 px-4 px-wd-5 font-weight-normal font-size-15 ">
                    Top Reviewed
                  </a>
                </li>
              </ul>
            </div>
            <!-- End Nav Links -->
            <!-- Tab Content -->
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade mb-5 mb-xl-0" id="pills-three-example1" role="tabpanel" aria-labelledby="pills-three-example1-tab"
                   data-target-group="groups">
                <ul class="d-block list-unstyled products-group prodcut-list-view">
                  <li class="card mb-5 overflow-hidden">
                    <div class="product-item__outer w-100">
                      <div class="row">
                        <div class="col-md-5 col-lg-6 col-xl-4">
                          <div class="product-item__header">
                            <div class="position-relative">
                              <div class="js-slick-carousel u-slick u-slick--equal-height " data-slides-show="1" data-slides-scroll="1" data-arrows-classes="d-none d-lg-inline-block u-slick__arrow-classic v4 u-slick__arrow-classic--v4 u-slick__arrow-centered--y rounded-circle"
                                   data-arrow-left-classes="flaticon-back u-slick__arrow-classic-inner u-slick__arrow-classic-inner--left"
                                   data-arrow-right-classes="flaticon-next u-slick__arrow-classic-inner u-slick__arrow-classic-inner--right"
                                   data-pagi-classes="js-pagination text-center u-slick__pagination u-slick__pagination--white position-absolute right-0 bottom-0 left-0 mb-3 mb-0">
                                <div class="js-slide">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                                    <img class="img-fluid" src="../../assets/img/300x230/img29.jpg">
                                  </a>
                                </div>
                                <div class="js-slide">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                                    <img class="img-fluid" src="../../assets/img/300x230/img30.jpg">
                                  </a>
                                </div>
                                <div class="js-slide">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                                    <img class="img-fluid" src="../../assets/img/300x230/img31.jpg">
                                  </a>
                                </div>
                                <div class="js-slide">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                                    <img class="img-fluid" src="../../assets/img/300x230/img32.jpg">
                                  </a>
                                </div>
                                <div class="js-slide">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                                    <img class="img-fluid" src="../../assets/img/300x230/img33.jpg">
                                  </a>
                                </div>
                                <div class="js-slide">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                                    <img class="img-fluid" src="../../assets/img/300x230/img34.jpg">
                                  </a>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div class="col col-md-7 col-lg-6 col-xl-5 flex-horizontal-center">
                          <div class="w-100 position-relative m-4 m-md-0">
                            <div class="position-absolute top-0 right-0 pr-md-3 d-none d-md-block">
                              <button type="button" class="btn btn-sm btn-icon rounded-circle" data-toggle="tooltip" data-placement="top" title="" data-original-title="Save for later">
                                <span class="flaticon-heart-1 font-size-20"></span>
                              </button>
                            </div>
                            <a href="../cars/cars-single-v1.html">
                              <span class="font-weight-bold font-size-17 text-dark text-dark">Mercedes-Benz A 200 CDI </span>
                            </a>
                            <div class="card-body p-0 mt-2">
                              <a href="../cars/cars-single-v1.html" class="d-block">
                                <div class="d-flex align-items-center font-size-14 text-gray-1">
                                  <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                                  Greater London, United Kingdom
                                </div>
                              </a>
                              <div class="mt-1">
                                <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                                  2.5/5 Excellant
                                </span>
                                <span class="font-size-14 text-gray-1 ml-2">
                                  48 reviews
                                </span>
                              </div>
                            </div>
                            <div class="mt-2 py-2">
                              <div class="d-flex">
                                <div class="ml-0">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-meter font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        49993 mi
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-cardinal-points font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Automatic
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div class="ml-5">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-fuel font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Diesel
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-event font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Calender
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-xl-3 align-self-center py-4 py-xl-0 border-top border-xl-top-0">
                          <div class="flex-content-center border-xl-left py-xl-5 ml-4 ml-xl-0 justify-content-start justify-content-xl-center">
                            <div class="text-center my-xl-1">
                              <div class="mb-2 pb-1">
                                <span class="font-weight-bold font-size-22">£899.00</span>
                                <span class="font-size-14"> / night</span>
                              </div>
                              <a href="../cars/cars-single-v1.html" class="btn btn-outline-primary d-flex align-items-center justify-content-center font-weight-bold min-height-50 border-radius-3 border-width-2 px-2 px-5 py-2">
                                View Detail
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="card mb-5 overflow-hidden">
                    <div class="product-item__outer w-100">
                      <div class="row align-items-center">
                        <div class="col-md-5 col-lg-6 col-xl-4">
                          <div class="product-item__header">
                            <div class="position-relative">
                              <div class="js-slick-carousel u-slick u-slick--equal-height " data-slides-show="1" data-slides-scroll="1" data-arrows-classes="d-none d-lg-inline-block u-slick__arrow-classic v4 u-slick__arrow-classic--v4 u-slick__arrow-centered--y rounded-circle"
                                   data-arrow-left-classes="flaticon-back u-slick__arrow-classic-inner u-slick__arrow-classic-inner--left"
                                   data-arrow-right-classes="flaticon-next u-slick__arrow-classic-inner u-slick__arrow-classic-inner--right"
                                   data-pagi-classes="js-pagination text-center u-slick__pagination u-slick__pagination--white position-absolute right-0 bottom-0 left-0 mb-3 mb-0">
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img30.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img29.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img31.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img32.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img33.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img34.jpg">
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-md-7 col-lg-6 col-xl-5 flex-horizontal-center">
                          <div class="w-100 position-relative m-4 m-md-0">
                            <div class="position-absolute top-0 right-0 pr-md-3 d-none d-md-block">
                              <button type="button" class="btn btn-sm btn-icon rounded-circle" data-toggle="tooltip" data-placement="top" title="" data-original-title="Save for later">
                                <span class="flaticon-heart-1 font-size-20"></span>
                              </button>
                            </div>
                            <a href="../cars/cars-single-v1.html">
                              <span class="font-weight-bold font-size-17 text-dark">Honda Civic 2019</span>
                            </a>
                            <div class="card-body p-0 mt-2">
                              <a href="../cars/cars-single-v1.html" class="d-block">
                                <div class="d-flex align-items-center font-size-14 text-gray-1">
                                  <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                                  Greater London, United Kingdom
                                </div>
                              </a>
                              <div class="mt-1">
                                <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                                  2.5/5 Excellant
                                </span>
                                <span class="font-size-14 text-gray-1 ml-2">
                                  48 reviews
                                </span>
                              </div>
                            </div>
                            <div class="mt-2 py-2">
                              <div class="d-flex">
                                <div class="ml-0">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-meter font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        49993 mi
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-cardinal-points font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Automatic
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div class="ml-5">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-fuel font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Diesel
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-event font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Calender
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-xl-3 align-self-center py-4 py-xl-0 border-top border-xl-top-0">
                          <div class="flex-content-center border-xl-left py-xl-5 ml-4 ml-xl-0 justify-content-start justify-content-xl-center">
                            <div class="text-center my-xl-1">
                              <div class="mb-2 pb-1">
                                <span class="font-weight-bold font-size-22">£899.00</span>
                                <span class="font-size-14"> / night</span>
                              </div>
                              <a href="../cars/cars-single-v1.html" class="btn btn-outline-primary d-flex align-items-center justify-content-center font-weight-bold min-height-50 border-radius-3 border-width-2 px-2 px-5 py-2">
                                View Detail
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="card mb-5 overflow-hidden">
                    <div class="product-item__outer w-100">
                      <div class="row align-items-center">
                        <div class="col-md-5 col-lg-6 col-xl-4">
                          <div class="product-item__header">
                            <div class="position-relative">
                              <div class="js-slick-carousel u-slick u-slick--equal-height " data-slides-show="1" data-slides-scroll="1" data-arrows-classes="d-none d-lg-inline-block u-slick__arrow-classic v4 u-slick__arrow-classic--v4 u-slick__arrow-centered--y rounded-circle"
                                   data-arrow-left-classes="flaticon-back u-slick__arrow-classic-inner u-slick__arrow-classic-inner--left"
                                   data-arrow-right-classes="flaticon-next u-slick__arrow-classic-inner u-slick__arrow-classic-inner--right"
                                   data-pagi-classes="js-pagination text-center u-slick__pagination u-slick__pagination--white position-absolute right-0 bottom-0 left-0 mb-3 mb-0">
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img34.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img29.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img30.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img31.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img32.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img33.jpg">
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-md-7 col-lg-6 col-xl-5 flex-horizontal-center">
                          <div class="w-100 position-relative m-4 m-md-0">
                            <div class="position-absolute top-0 right-0 pr-md-3 d-none d-md-block">
                              <button type="button" class="btn btn-sm btn-icon rounded-circle" data-toggle="tooltip" data-placement="top" title="" data-original-title="Save for later">
                                <span class="flaticon-heart-1 font-size-20"></span>
                              </button>
                            </div>
                            <a href="../cars/cars-single-v2.html">
                              <span class="font-weight-bold font-size-17 text-dark"> Audi A3 1.6 TDI S line</span>
                            </a>
                            <div class="card-body p-0 mt-2">
                              <a href="../cars/cars-single-v2.html" class="d-block">
                                <div class="d-flex align-items-center font-size-14 text-gray-1">
                                  <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                                  Greater London, United Kingdom
                                </div>
                              </a>
                              <div class="mt-1">
                                <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                                  2.5/5 Excellant
                                </span>
                                <span class="font-size-14 text-gray-1 ml-2">
                                  48 reviews
                                </span>
                              </div>
                            </div>
                            <div class="mt-2 py-2">
                              <div class="d-flex">
                                <div class="ml-0">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-meter font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        49993 mi
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-cardinal-points font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Automatic
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div class="ml-5">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-fuel font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Diesel
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-event font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Calender
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-xl-3 align-self-center py-4 py-xl-0 border-top border-xl-top-0">
                          <div class="flex-content-center border-xl-left py-xl-5 ml-4 ml-xl-0 justify-content-start justify-content-xl-center">
                            <div class="text-center my-xl-1">
                              <div class="mb-2 pb-1">
                                <span class="font-weight-bold font-size-22">£899.00</span>
                                <span class="font-size-14"> / night</span>
                              </div>
                              <a href="../cars/cars-single-v1.html" class="btn btn-outline-primary d-flex align-items-center justify-content-center font-weight-bold min-height-50 border-radius-3 border-width-2 px-2 px-5 py-2">
                                View Detail
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="card mb-5 overflow-hidden">
                    <div class="product-item__outer w-100">
                      <div class="row align-items-center">
                        <div class="col-md-5 col-lg-6 col-xl-4">
                          <div class="product-item__header">
                            <div class="position-relative">
                              <div class="js-slick-carousel u-slick u-slick--equal-height " data-slides-show="1" data-slides-scroll="1" data-arrows-classes="d-none d-lg-inline-block u-slick__arrow-classic v4 u-slick__arrow-classic--v4 u-slick__arrow-centered--y rounded-circle"
                                   data-arrow-left-classes="flaticon-back u-slick__arrow-classic-inner u-slick__arrow-classic-inner--left"
                                   data-arrow-right-classes="flaticon-next u-slick__arrow-classic-inner u-slick__arrow-classic-inner--right"
                                   data-pagi-classes="js-pagination text-center u-slick__pagination u-slick__pagination--white position-absolute right-0 bottom-0 left-0 mb-3 mb-0">
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img33.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img29.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img30.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img31.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img32.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img34.jpg">
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-md-7 col-lg-6 col-xl-5 flex-horizontal-center">
                          <div class="w-100 position-relative m-4 m-md-0">
                            <div class="position-absolute top-0 right-0 pr-md-3 d-none d-md-block">
                              <button type="button" class="btn btn-sm btn-icon rounded-circle" data-toggle="tooltip" data-placement="top" title="" data-original-title="Save for later">
                                <span class="flaticon-heart-1 font-size-20"></span>
                              </button>
                            </div>
                            <a href="../cars/cars-single-v2.html">
                              <span class="font-weight-bold font-size-17 text-dark text-dark">Mercedes-Benz A 200 CDI </span>
                            </a>
                            <div class="card-body p-0 mt-2">
                              <a href="../cars/cars-single-v2.html" class="d-block">
                                <div class="d-flex align-items-center font-size-14 text-gray-1">
                                  <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                                  Greater London, United Kingdom
                                </div>
                              </a>
                              <div class="mt-1">
                                <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                                  2.5/5 Excellant
                                </span>
                                <span class="font-size-14 text-gray-1 ml-2">
                                  48 reviews
                                </span>
                              </div>
                            </div>
                            <div class="mt-2 py-2">
                              <div class="d-flex">
                                <div class="ml-0">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-meter font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        49993 mi
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-cardinal-points font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Automatic
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div class="ml-5">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-fuel font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Diesel
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-event font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Calender
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-xl-3 align-self-center py-4 py-xl-0 border-top border-xl-top-0">
                          <div class="flex-content-center border-xl-left py-xl-5 ml-4 ml-xl-0 justify-content-start justify-content-xl-center">
                            <div class="text-center my-xl-1">
                              <div class="mb-2 pb-1">
                                <span class="font-weight-bold font-size-22">£899.00</span>
                                <span class="font-size-14"> / night</span>
                              </div>
                              <a href="../cars/cars-single-v1.html" class="btn btn-outline-primary d-flex align-items-center justify-content-center font-weight-bold min-height-50 border-radius-3 border-width-2 px-2 px-5 py-2">
                                View Detail
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="card mb-5 overflow-hidden">
                    <div class="product-item__outer w-100">
                      <div class="row align-items-center">
                        <div class="col-md-5 col-lg-6 col-xl-4">
                          <div class="product-item__header">
                            <div class="position-relative">
                              <div class="js-slick-carousel u-slick u-slick--equal-height " data-slides-show="1" data-slides-scroll="1" data-arrows-classes="d-none d-lg-inline-block u-slick__arrow-classic v4 u-slick__arrow-classic--v4 u-slick__arrow-centered--y rounded-circle"
                                   data-arrow-left-classes="flaticon-back u-slick__arrow-classic-inner u-slick__arrow-classic-inner--left"
                                   data-arrow-right-classes="flaticon-next u-slick__arrow-classic-inner u-slick__arrow-classic-inner--right"
                                   data-pagi-classes="js-pagination text-center u-slick__pagination u-slick__pagination--white position-absolute right-0 bottom-0 left-0 mb-3 mb-0">
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img32.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img29.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img30.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img31.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img33.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img34.jpg">
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-md-7 col-lg-6 col-xl-5 flex-horizontal-center">
                          <div class="w-100 position-relative m-4 m-md-0">
                            <div class="position-absolute top-0 right-0 pr-md-3 d-none d-md-block">
                              <button type="button" class="btn btn-sm btn-icon rounded-circle" data-toggle="tooltip" data-placement="top" title="" data-original-title="Save for later">
                                <span class="flaticon-heart-1 font-size-20"></span>
                              </button>
                            </div>
                            <a href="../cars/cars-single-v1.html">
                              <span class="font-weight-bold font-size-17 text-dark"> Audi A3 1.6 TDI S line</span>
                            </a>
                            <div class="card-body p-0 mt-2">
                              <a href="../cars/cars-single-v1.html" class="d-block">
                                <div class="d-flex align-items-center font-size-14 text-gray-1">
                                  <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                                  Greater London, United Kingdom
                                </div>
                              </a>
                              <div class="mt-1">
                                <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                                  2.5/5 Excellant
                                </span>
                                <span class="font-size-14 text-gray-1 ml-2">
                                  48 reviews
                                </span>
                              </div>
                            </div>
                            <div class="mt-2 py-2">
                              <div class="d-flex">
                                <div class="ml-0">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-meter font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        49993 mi
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-cardinal-points font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Automatic
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div class="ml-5">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-fuel font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Diesel
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-event font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Calender
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-xl-3 align-self-center py-4 py-xl-0 border-top border-xl-top-0">
                          <div class="flex-content-center border-xl-left py-xl-5 ml-4 ml-xl-0 justify-content-start justify-content-xl-center">
                            <div class="text-center my-xl-1">
                              <div class="mb-2 pb-1">
                                <span class="font-weight-bold font-size-22">£899.00</span>
                                <span class="font-size-14"> / night</span>
                              </div>
                              <a href="../cars/cars-single-v1.html" class="btn btn-outline-primary d-flex align-items-center justify-content-center font-weight-bold min-height-50 border-radius-3 border-width-2 px-2 px-5 py-2">
                                View Detail
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="card mb-5 overflow-hidden">
                    <div class="product-item__outer w-100">
                      <div class="row align-items-center">
                        <div class="col-md-5 col-lg-6 col-xl-4">
                          <div class="product-item__header">
                            <div class="position-relative">
                              <div class="js-slick-carousel u-slick u-slick--equal-height " data-slides-show="1" data-slides-scroll="1" data-arrows-classes="d-none d-lg-inline-block u-slick__arrow-classic v4 u-slick__arrow-classic--v4 u-slick__arrow-centered--y rounded-circle"
                                   data-arrow-left-classes="flaticon-back u-slick__arrow-classic-inner u-slick__arrow-classic-inner--left"
                                   data-arrow-right-classes="flaticon-next u-slick__arrow-classic-inner u-slick__arrow-classic-inner--right"
                                   data-pagi-classes="js-pagination text-center u-slick__pagination u-slick__pagination--white position-absolute right-0 bottom-0 left-0 mb-3 mb-0">
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img31.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img29.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img30.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img32.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img33.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img34.jpg">
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-md-7 col-lg-6 col-xl-5 flex-horizontal-center">
                          <div class="w-100 position-relative m-4 m-md-0">
                            <div class="position-absolute top-0 right-0 pr-md-3 d-none d-md-block">
                              <button type="button" class="btn btn-sm btn-icon rounded-circle" data-toggle="tooltip" data-placement="top" title="" data-original-title="Save for later">
                                <span class="flaticon-heart-1 font-size-20"></span>
                              </button>
                            </div>
                            <a href="../cars/cars-single-v1.html">
                              <span class="font-weight-bold font-size-17 text-dark">Honda Civic 2019</span>
                            </a>
                            <div class="card-body p-0 mt-2">
                              <a href="../cars/cars-single-v1.html" class="d-block">
                                <div class="d-flex align-items-center font-size-14 text-gray-1">
                                  <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                                  Greater London, United Kingdom
                                </div>
                              </a>
                              <div class="mt-1">
                                <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                                  2.5/5 Excellant
                                </span>
                                <span class="font-size-14 text-gray-1 ml-2">
                                  48 reviews
                                </span>
                              </div>
                            </div>
                            <div class="mt-2 py-2">
                              <div class="d-flex">
                                <div class="ml-0">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-meter font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        49993 mi
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-cardinal-points font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Automatic
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div class="ml-5">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-fuel font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Diesel
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-event font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Calender
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-xl-3 align-self-center py-4 py-xl-0 border-top border-xl-top-0">
                          <div class="flex-content-center border-xl-left py-xl-5 ml-4 ml-xl-0 justify-content-start justify-content-xl-center">
                            <div class="text-center my-xl-1">
                              <div class="mb-2 pb-1">
                                <span class="font-weight-bold font-size-22">£899.00</span>
                                <span class="font-size-14"> / night</span>
                              </div>
                              <a href="../cars/cars-single-v1.html" class="btn btn-outline-primary d-flex align-items-center justify-content-center font-weight-bold min-height-50 border-radius-3 border-width-2 px-2 px-5 py-2">
                                View Detail
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="card mb-5 overflow-hidden">
                    <div class="product-item__outer w-100">
                      <div class="row align-items-center">
                        <div class="col-md-5 col-lg-6 col-xl-4">
                          <div class="product-item__header">
                            <div class="position-relative">
                              <div class="js-slick-carousel u-slick u-slick--equal-height " data-slides-show="1" data-slides-scroll="1" data-arrows-classes="d-none d-lg-inline-block u-slick__arrow-classic v4 u-slick__arrow-classic--v4 u-slick__arrow-centered--y rounded-circle"
                                   data-arrow-left-classes="flaticon-back u-slick__arrow-classic-inner u-slick__arrow-classic-inner--left"
                                   data-arrow-right-classes="flaticon-next u-slick__arrow-classic-inner u-slick__arrow-classic-inner--right"
                                   data-pagi-classes="js-pagination text-center u-slick__pagination u-slick__pagination--white position-absolute right-0 bottom-0 left-0 mb-3 mb-0">
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img32.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img29.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img30.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img31.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img33.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img34.jpg">
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-md-7 col-lg-6 col-xl-5 flex-horizontal-center">
                          <div class="w-100 position-relative m-4 m-md-0">
                            <div class="position-absolute top-0 right-0 pr-md-3 d-none d-md-block">
                              <button type="button" class="btn btn-sm btn-icon rounded-circle" data-toggle="tooltip" data-placement="top" title="" data-original-title="Save for later">
                                <span class="flaticon-heart-1 font-size-20"></span>
                              </button>
                            </div>
                            <a href="../cars/cars-single-v1.html">
                              <span class="font-weight-bold font-size-17 text-dark"> Audi A3 1.6 TDI S line</span>
                            </a>
                            <div class="card-body p-0 mt-2">
                              <a href="../cars/cars-single-v1.html" class="d-block">
                                <div class="d-flex align-items-center font-size-14 text-gray-1">
                                  <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                                  Greater London, United Kingdom
                                </div>
                              </a>
                              <div class="mt-1">
                                <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                                  2.5/5 Excellant
                                </span>
                                <span class="font-size-14 text-gray-1 ml-2">
                                  48 reviews
                                </span>
                              </div>
                            </div>
                            <div class="mt-2 py-2">
                              <div class="d-flex">
                                <div class="ml-0">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-meter font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        49993 mi
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-cardinal-points font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Automatic
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div class="ml-5">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-fuel font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Diesel
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-event font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Calender
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-xl-3 align-self-center py-4 py-xl-0 border-top border-xl-top-0">
                          <div class="flex-content-center border-xl-left py-xl-5 ml-4 ml-xl-0 justify-content-start justify-content-xl-center">
                            <div class="text-center my-xl-1">
                              <div class="mb-2 pb-1">
                                <span class="font-weight-bold font-size-22">£899.00</span>
                                <span class="font-size-14"> / night</span>
                              </div>
                              <a href="../cars/cars-single-v1.html" class="btn btn-outline-primary d-flex align-items-center justify-content-center font-weight-bold min-height-50 border-radius-3 border-width-2 px-2 px-5 py-2">
                                View Detail
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="card mb-5 overflow-hidden">
                    <div class="product-item__outer w-100">
                      <div class="row align-items-center">
                        <div class="col-md-5 col-lg-6 col-xl-4">
                          <div class="product-item__header">
                            <div class="position-relative">
                              <div class="js-slick-carousel u-slick u-slick--equal-height " data-slides-show="1" data-slides-scroll="1" data-arrows-classes="d-none d-lg-inline-block u-slick__arrow-classic v4 u-slick__arrow-classic--v4 u-slick__arrow-centered--y rounded-circle"
                                   data-arrow-left-classes="flaticon-back u-slick__arrow-classic-inner u-slick__arrow-classic-inner--left"
                                   data-arrow-right-classes="flaticon-next u-slick__arrow-classic-inner u-slick__arrow-classic-inner--right"
                                   data-pagi-classes="js-pagination text-center u-slick__pagination u-slick__pagination--white position-absolute right-0 bottom-0 left-0 mb-3 mb-0">
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img30.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img29.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img31.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img32.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img33.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img34.jpg">
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-md-7 col-lg-6 col-xl-5 flex-horizontal-center">
                          <div class="w-100 position-relative m-4 m-md-0">
                            <div class="position-absolute top-0 right-0 pr-md-3 d-none d-md-block">
                              <button type="button" class="btn btn-sm btn-icon rounded-circle" data-toggle="tooltip" data-placement="top" title="" data-original-title="Save for later">
                                <span class="flaticon-heart-1 font-size-20"></span>
                              </button>
                            </div>
                            <a href="../cars/cars-single-v2.html">
                              <span class="font-weight-bold font-size-17 text-dark text-dark">Mercedes-Benz A 200 CDI </span>
                            </a>
                            <div class="card-body p-0 mt-2">
                              <a href="../cars/cars-single-v2.html" class="d-block">
                                <div class="d-flex align-items-center font-size-14 text-gray-1">
                                  <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                                  Greater London, United Kingdom
                                </div>
                              </a>
                              <div class="mt-1">
                                <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                                  2.5/5 Excellant
                                </span>
                                <span class="font-size-14 text-gray-1 ml-2">
                                  48 reviews
                                </span>
                              </div>
                            </div>
                            <div class="mt-2 py-2">
                              <div class="d-flex">
                                <div class="ml-0">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-meter font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        49993 mi
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-cardinal-points font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Automatic
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div class="ml-5">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-fuel font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Diesel
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-event font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Calender
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-xl-3 align-self-center py-4 py-xl-0 border-top border-xl-top-0">
                          <div class="flex-content-center border-xl-left py-xl-5 ml-4 ml-xl-0 justify-content-start justify-content-xl-center">
                            <div class="text-center my-xl-1">
                              <div class="mb-2 pb-1">
                                <span class="font-weight-bold font-size-22">£899.00</span>
                                <span class="font-size-14"> / night</span>
                              </div>
                              <a href="../cars/cars-single-v1.html" class="btn btn-outline-primary d-flex align-items-center justify-content-center font-weight-bold min-height-50 border-radius-3 border-width-2 px-2 px-5 py-2">
                                View Detail
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="card mb-5 overflow-hidden">
                    <div class="product-item__outer w-100">
                      <div class="row align-items-center">
                        <div class="col-md-5 col-lg-6 col-xl-4">
                          <div class="product-item__header">
                            <div class="position-relative">
                              <div class="js-slick-carousel u-slick u-slick--equal-height " data-slides-show="1" data-slides-scroll="1" data-arrows-classes="d-none d-lg-inline-block u-slick__arrow-classic v4 u-slick__arrow-classic--v4 u-slick__arrow-centered--y rounded-circle"
                                   data-arrow-left-classes="flaticon-back u-slick__arrow-classic-inner u-slick__arrow-classic-inner--left"
                                   data-arrow-right-classes="flaticon-next u-slick__arrow-classic-inner u-slick__arrow-classic-inner--right"
                                   data-pagi-classes="js-pagination text-center u-slick__pagination u-slick__pagination--white position-absolute right-0 bottom-0 left-0 mb-3 mb-0">
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img29.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img30.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img31.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img32.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img33.jpg">
                                  </a>
                                </div>
                                <div class="js-slide w-100">
                                  <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5 w-100">
                                    <img class="min-height-230 card-img-top" src="../../assets/img/300x230/img34.jpg">
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-md-7 col-lg-6 col-xl-5 flex-horizontal-center">
                          <div class="w-100 position-relative m-4 m-md-0">
                            <div class="position-absolute top-0 right-0 pr-md-3 d-none d-md-block">
                              <button type="button" class="btn btn-sm btn-icon rounded-circle" data-toggle="tooltip" data-placement="top" title="" data-original-title="Save for later">
                                <span class="flaticon-heart-1 font-size-20"></span>
                              </button>
                            </div>
                            <a href="../cars/cars-single-v2.html">
                              <span class="font-weight-bold font-size-17 text-dark">Honda Civic 2019</span>
                            </a>
                            <div class="card-body p-0 mt-2">
                              <a href="../cars/cars-single-v2.html" class="d-block">
                                <div class="d-flex align-items-center font-size-14 text-gray-1">
                                  <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                                  Greater London, United Kingdom
                                </div>
                              </a>
                              <div class="mt-1">
                                <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                                  2.5/5 Excellant
                                </span>
                                <span class="font-size-14 text-gray-1 ml-2">
                                  48 reviews
                                </span>
                              </div>
                            </div>
                            <div class="mt-2 py-2">
                              <div class="d-flex">
                                <div class="ml-0">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-meter font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        49993 mi
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-cardinal-points font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Automatic
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div class="ml-5">
                                  <ul class="list-unstyled mb-0">
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-fuel font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Diesel
                                      </div>
                                    </li>
                                    <li class="media mb-2 text-gray-1">
                                      <small class="mr-2">
                                        <small class="flaticon-event font-size-16"></small>
                                      </small>
                                      <div class="media-body font-size-1">
                                        Calender
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col col-xl-3 align-self-center py-4 py-xl-0 border-top border-xl-top-0">
                          <div class="flex-content-center border-xl-left py-xl-5 ml-4 ml-xl-0 justify-content-start justify-content-xl-center">
                            <div class="text-center my-xl-1">
                              <div class="mb-2 pb-1">
                                <span class="font-weight-bold font-size-22">£899.00</span>
                                <span class="font-size-14"> / night</span>
                              </div>
                              <a href="../cars/cars-single-v1.html" class="btn btn-outline-primary d-flex align-items-center justify-content-center font-weight-bold min-height-50 border-radius-3 border-width-2 px-2 px-5 py-2">
                                View Detail
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="text-center text-md-left font-size-14 mb-3 text-lh-1">Showing 1–15</div>
                <nav aria-label="Page navigation">
                  <ul class="list-pagination-1 pagination border border-color-4 rounded-sm mb-5 mb-lg-0 overflow-auto overflow-xl-visible justify-content-md-center align-items-center py-2">
                    <li class="page-item">
                      <a class="page-link border-right rounded-0 text-gray-5" href="#" aria-label="Previous">
                        <i class="flaticon-left-direction-arrow font-size-10 font-weight-bold"></i>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14" href="#">1</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14 active" href="#">2</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14" href="#">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14" href="#">4</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14" href="#">5</a>
                    </li>
                    <li class="page-item disabled">
                      <a class="page-link font-size-14" href="#">...</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14" href="#">66</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14" href="#">67</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link border-left rounded-0 text-gray-5" href="#" aria-label="Next">
                        <i class="flaticon-right-thin-chevron font-size-10 font-weight-bold"></i>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="tab-pane fade mb-5 mb-xl-0 show active" id="pills-one-example1" role="tabpanel" aria-labelledby="pills-one-example1-tab"
                   data-target-group="groups">
                <div class="row">
                  <div class="col-md-6 col-xl-4 mb-3 mb-md-4 pb-1">
                    <div class="card transition-3d-hover shadow-hover-2">
                      <div class="position-relative">
                        <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                          <img class="card-img-top" src="../../assets/img/300x230/img29.jpg">
                        </a>
                        <div class="position-absolute top-0 right-0 pt-5 pr-3">
                          <button type="button" class="btn btn-sm btn-icon text-white rounded-circle" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="Save for later">
                            <span class="flaticon-heart-1 font-size-20"></span>
                          </button>
                        </div>
                        <div class="position-absolute bottom-0 left-0 right-0">
                          <div class="px-3 pb-2">
                            <a href="../cars/cars-single-v1.html">
                              <span class="text-white font-weight-bold font-size-17">Mercedes-Benz A 200 CDI </span>
                            </a>
                            <div class="text-white my-2">
                              <span class="mr-1 font-size-14">From</span>
                              <span class="font-weight-bold font-size-19">£899.00</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="position-absolute top-0 left-0 pt-5 pl-3">
                        <a href="../cars/cars-single-v1.html">
                          <span class="badge badge-pill bg-white text-primary px-3 py-2 font-size-14 font-weight-normal">Featured</span>
                        </a>
                      </div>
                      <div class="card-body px-4 py-3 border-bottom">
                        <a href="../cars/cars-single-v1.html" class="d-block">
                          <div class="d-flex align-items-center font-size-14 text-gray-1">
                            <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                            Greater London, United Kingdom
                          </div>
                        </a>
                        <div class="mt-1">
                          <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                            2.5/5 Excellant
                          </span>
                          <span class="font-size-14 text-gray-1 ml-2">48 reviews</span>
                        </div>
                      </div>
                      <div class="px-4 pt-3 pb-2">
                        <div class="row">
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-meter font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  49993 mi
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-cardinal-points font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Automatic
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-fuel font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Diesel
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-event font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Calender
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-4 mb-3 mb-md-4 pb-1">
                    <div class="card transition-3d-hover shadow-hover-2">
                      <div class="position-relative">
                        <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                          <img class="card-img-top" src="../../assets/img/300x230/img30.jpg">
                        </a>
                        <div class="position-absolute top-0 right-0 pt-5 pr-3">
                          <button type="button" class="btn btn-sm btn-icon text-white rounded-circle" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="Save for later">
                            <span class="flaticon-heart-1 font-size-20"></span>
                          </button>
                        </div>
                        <div class="position-absolute bottom-0 left-0 right-0">
                          <div class="px-3 pb-2">
                            <a href="../cars/cars-single-v1.html">
                              <span class="text-white font-weight-bold font-size-17">BMW 316 D FaceLift</span>
                            </a>
                            <div class="text-white my-2">
                              <span class="mr-1 font-size-14">From</span>
                              <span class="font-weight-bold font-size-19">£899.00</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body px-4 py-3 border-bottom">
                        <a href="../cars/cars-single-v1.html" class="d-block">
                          <div class="d-flex align-items-center font-size-14 text-gray-1">
                            <i class="icon flaticon-placeholder mr-2 font-size-20"></i> New South Wales, Australia
                          </div>
                        </a>
                        <div class="mt-1">
                          <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                            2.5/5 Excellant
                          </span>
                          <span class="font-size-14 text-gray-1 ml-2">48 reviews</span>
                        </div>
                      </div>
                      <div class="px-4 pt-3 pb-2">
                        <div class="row">
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-meter font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  49993 mi
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-cardinal-points font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Automatic
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-fuel font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Diesel
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-event font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Calender
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-4 mb-3 mb-md-4 pb-1">
                    <div class="card transition-3d-hover shadow-hover-2">
                      <div class="position-relative">
                        <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                          <img class="card-img-top" src="../../assets/img/300x230/img31.jpg">
                        </a>
                        <div class="position-absolute top-0 right-0 pt-5 pr-3">
                          <button type="button" class="btn btn-sm btn-icon text-white rounded-circle" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="Save for later">
                            <span class="flaticon-heart-1 font-size-20"></span>
                          </button>
                        </div>
                        <div class="position-absolute bottom-0 left-0 right-0">
                          <div class="px-3 pb-2">
                            <a href="../cars/cars-single-v1.html">
                              <span class="text-white font-weight-bold font-size-17"> Audi A3 1.6 TDI S line</span>
                            </a>
                            <div class="text-white my-2">
                              <span class="mr-1 font-size-14">From</span>
                              <span class="font-weight-bold font-size-19">£899.00</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body px-4 py-3 border-bottom">
                        <a href="../cars/cars-single-v1.html" class="d-block">
                          <div class="d-flex align-items-center font-size-14 text-gray-1">
                            <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                            Istanbul , Turkey
                          </div>
                        </a>
                        <div class="mt-1">
                          <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                            2.5/5 Excellant
                          </span>
                          <span class="font-size-14 text-gray-1 ml-2">48 reviews</span>
                        </div>
                      </div>
                      <div class="px-4 pt-3 pb-2">
                        <div class="row">
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-meter font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  49993 mi
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-fuel font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Diesel
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-cardinal-points font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Automatic
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-event font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Calender
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-4 mb-3 mb-md-4 pb-1">
                    <div class="card transition-3d-hover shadow-hover-2">
                      <div class="position-relative">
                        <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                          <img class="card-img-top" src="../../assets/img/300x230/img32.jpg">
                        </a>
                        <div class="position-absolute top-0 right-0 pt-5 pr-3">
                          <button type="button" class="btn btn-sm btn-icon text-white rounded-circle" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="Save for later">
                            <span class="flaticon-heart-1 font-size-20"></span>
                          </button>
                        </div>
                        <div class="position-absolute bottom-0 left-0 right-0">
                          <div class="px-3 pb-2">
                            <h6 class="text-white font-weight-bold font-size-17">
                              <a href="../cars/cars-single-v1.html">Honda Civic 2019</a>
                            </h6>
                            <div class="text-white my-2">
                              <span class="mr-1 font-size-14">From</span>
                              <span class="font-weight-bold font-size-19">£899.00</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body px-4 py-3 border-bottom">
                        <a href="../cars/cars-single-v1.html" class="d-block">
                          <div class="d-flex align-items-center font-size-14 text-gray-1">
                            <i class="icon flaticon-placeholder mr-2 font-size-20"></i> New York, United States
                          </div>
                        </a>
                        <div class="mt-1">
                          <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                            2.5/5 Excellant
                          </span>
                          <span class="font-size-14 text-gray-1 ml-2">48 reviews</span>
                        </div>
                      </div>
                      <div class="px-4 pt-3 pb-2">
                        <div class="row">
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-meter font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  49993 mi
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-cardinal-points font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Automatic
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-fuel font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Diesel
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-event font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Calender
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-4 mb-3 mb-md-4 pb-1">
                    <div class="card transition-3d-hover shadow-hover-2">
                      <div class="position-relative">
                        <a href="../cars/cars-single-v1.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                          <img class="card-img-top" src="../../assets/img/300x230/img33.jpg">
                        </a>
                        <div class="position-absolute top-0 right-0 pt-5 pr-3">
                          <button type="button" class="btn btn-sm btn-icon text-white rounded-circle" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="Save for later">
                            <span class="flaticon-heart-1 font-size-20"></span>
                          </button>
                        </div>
                        <div class="position-absolute bottom-0 left-0 right-0">
                          <div class="px-3 pb-2">
                            <a href="../cars/cars-single-v1.html">
                              <span class="text-white font-weight-bold font-size-17">BMW 316 D FaceLift</span>
                            </a>
                            <div class="text-white my-2">
                              <span class="mr-1 font-size-14">From</span>
                              <span class="font-weight-bold font-size-19">£899.00</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body px-4 py-3 border-bottom">
                        <a href="../cars/cars-single-v1.html" class="d-block">
                          <div class="d-flex align-items-center font-size-14 text-gray-1">
                            <i class="icon flaticon-placeholder mr-2 font-size-20"></i> New South Wales, Australia
                          </div>
                        </a>
                        <div class="mt-1">
                          <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                            2.5/5 Excellant
                          </span>
                          <span class="font-size-14 text-gray-1 ml-2">48 reviews</span>
                        </div>
                      </div>
                      <div class="px-4 pt-3 pb-2">
                        <div class="row">
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-meter font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  49993 mi
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-cardinal-points font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Automatic
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-fuel font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Diesel
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-event font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Calender
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-4 mb-3 mb-md-4 pb-1">
                    <div class="card transition-3d-hover shadow-hover-2">
                      <div class="position-relative">
                        <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                          <img class="card-img-top" src="../../assets/img/300x230/img34.jpg">
                        </a>
                        <div class="position-absolute top-0 right-0 pt-5 pr-3">
                          <button type="button" class="btn btn-sm btn-icon text-white rounded-circle" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="Save for later">
                            <span class="flaticon-heart-1 font-size-20"></span>
                          </button>
                        </div>
                        <div class="position-absolute bottom-0 left-0 right-0">
                          <div class="px-3 pb-2">
                            <a href="../cars/cars-single-v2.html">
                              <span class="text-white font-weight-bold font-size-17"> Audi A3 1.6 TDI S line</span>
                            </a>
                            <div class="text-white my-2">
                              <span class="mr-1 font-size-14">From</span>
                              <span class="font-weight-bold font-size-19">£899.00</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body px-4 py-3 border-bottom">
                        <a href="../cars/cars-single-v2.html" class="d-block">
                          <div class="d-flex align-items-center font-size-14 text-gray-1">
                            <i class="icon flaticon-placeholder mr-2 font-size-20"></i> New York, United States
                          </div>
                        </a>
                        <div class="mt-1">
                          <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                            2.5/5 Excellant
                          </span>
                          <span class="font-size-14 text-gray-1 ml-2">48 reviews</span>
                        </div>
                      </div>
                      <div class="px-4 pt-3 pb-2">
                        <div class="row">
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-meter font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  49993 mi
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-cardinal-points font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Automatic
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-fuel font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Diesel
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-event font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Calender
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-4 mb-3 mb-md-4 pb-1">
                    <div class="card transition-3d-hover shadow-hover-2">
                      <div class="position-relative">
                        <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                          <img class="card-img-top" src="../../assets/img/300x230/img35.jpg">
                        </a>
                        <div class="position-absolute top-0 right-0 pt-5 pr-3">
                          <button type="button" class="btn btn-sm btn-icon text-white rounded-circle" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="Save for later">
                            <span class="flaticon-heart-1 font-size-20"></span>
                          </button>
                        </div>
                        <div class="position-absolute bottom-0 left-0 right-0">
                          <div class="px-3 pb-2">
                            <a href="../cars/cars-single-v2.html">
                              <span class="text-white font-weight-bold font-size-17"> Audi A3 1.6 TDI S line</span>
                            </a>
                            <div class="text-white my-2">
                              <span class="mr-1 font-size-14">From</span>
                              <span class="font-weight-bold font-size-19">£899.00</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="position-absolute top-0 left-0 pt-5 pl-3">
                        <a href="../cars/cars-single-v2.html">
                          <span class="badge badge-pill bg-white text-primary px-3 py-2 font-size-14 font-weight-normal">Featured</span>
                        </a>
                      </div>
                      <div class="card-body px-4 py-3 border-bottom">
                        <a href="../cars/cars-single-v2.html" class="d-block">
                          <div class="d-flex align-items-center font-size-14 text-gray-1">
                            <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                            Istanbul , Turkey
                          </div>
                        </a>
                        <div class="mt-1">
                          <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                            2.5/5 Excellant
                          </span>
                          <span class="font-size-14 text-gray-1 ml-2">48 reviews</span>
                        </div>
                      </div>
                      <div class="px-4 pt-3 pb-2">
                        <div class="row">
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-meter font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  49993 mi
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-fuel font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Diesel
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-cardinal-points font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Automatic
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-event font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Calender
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-4 mb-3 mb-md-4 pb-1">
                    <div class="card transition-3d-hover shadow-hover-2">
                      <div class="position-relative">
                        <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                          <img class="card-img-top" src="../../assets/img/300x230/img36.jpg">
                        </a>
                        <div class="position-absolute top-0 right-0 pt-5 pr-3">
                          <button type="button" class="btn btn-sm btn-icon text-white rounded-circle" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="Save for later">
                            <span class="flaticon-heart-1 font-size-20"></span>
                          </button>
                        </div>
                        <div class="position-absolute bottom-0 left-0 right-0">
                          <div class="px-3 pb-2">
                            <a href="../cars/cars-single-v2.html">
                              <span class="text-white font-weight-bold font-size-17">Mercedes-Benz A 200 CDI</span>
                            </a>
                            <div class="text-white my-2">
                              <span class="mr-1 font-size-14">From</span>
                              <span class="font-weight-bold font-size-19">£899.00</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body px-4 py-3 border-bottom">
                        <a href="../cars/cars-single-v2.html" class="d-block">
                          <div class="d-flex align-items-center font-size-14 text-gray-1">
                            <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                            Istanbul , Turkey
                          </div>
                        </a>
                        <div class="mt-1">
                          <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                            2.5/5 Excellant
                          </span>
                          <span class="font-size-14 text-gray-1 ml-2">48 reviews</span>
                        </div>
                      </div>
                      <div class="px-4 pt-3 pb-2">
                        <div class="row">
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-meter font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  49993 mi
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-fuel font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Diesel
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-cardinal-points font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Automatic
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-event font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Calender
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-4 mb-3 mb-md-4 pb-1">
                    <div class="card transition-3d-hover shadow-hover-2">
                      <div class="position-relative">
                        <a href="../cars/cars-single-v2.html" class="d-block gradient-overlay-half-bg-gradient-v5">
                          <img class="card-img-top" src="../../assets/img/300x230/img37.jpg">
                        </a>
                        <div class="position-absolute top-0 right-0 pt-5 pr-3">
                          <button type="button" class="btn btn-sm btn-icon text-white rounded-circle" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="Save for later">
                            <span class="flaticon-heart-1 font-size-20"></span>
                          </button>
                        </div>
                        <div class="position-absolute bottom-0 left-0 right-0">
                          <div class="px-3 pb-2">
                            <a href="../cars/cars-single-v2.html">
                              <span class="text-white font-weight-bold font-size-17">Mercedes-Benz A 200 CDI</span>
                            </a>
                            <div class="text-white my-2">
                              <span class="mr-1 font-size-14">From</span>
                              <span class="font-weight-bold font-size-19">£899.00</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body px-4 py-3 border-bottom">
                        <a href="../cars/cars-single-v2.html" class="d-block">
                          <div class="d-flex align-items-center font-size-14 text-gray-1">
                            <i class="icon flaticon-placeholder mr-2 font-size-20"></i>
                            Istanbul , Turkey
                          </div>
                        </a>
                        <div class="mt-1">
                          <span class="py-1 font-size-14 border-radius-3 font-weight-normal pagination-v2-arrow-color">
                            2.5/5 Excellant
                          </span>
                          <span class="font-size-14 text-gray-1 ml-2">48 reviews</span>
                        </div>
                      </div>
                      <div class="px-4 pt-3 pb-2">
                        <div class="row">
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-meter font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  49993 mi
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-fuel font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Diesel
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="col-6">
                            <ul class="list-unstyled mb-0">
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-cardinal-points font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Automatic
                                </div>
                              </li>
                              <li class="media mb-2 text-gray-1 align-items-center">
                                <small class="mr-2">
                                  <small class="flaticon-event font-size-16"></small>
                                </small>
                                <div class="media-body font-size-1">
                                  Calender
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center text-md-left font-size-14 mb-3 text-lh-1">Showing 1–15</div>
                <nav aria-label="Page navigation">
                  <ul class="list-pagination-1 pagination border border-color-4 rounded-sm mb-5 mb-lg-0 overflow-auto overflow-xl-visible justify-content-md-center align-items-center py-2">
                    <li class="page-item">
                      <a class="page-link border-right rounded-0 text-gray-5" href="#" aria-label="Previous">
                        <i class="flaticon-left-direction-arrow font-size-10 font-weight-bold"></i>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14" href="#">1</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14 active" href="#">2</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14" href="#">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14" href="#">4</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14" href="#">5</a>
                    </li>
                    <li class="page-item disabled">
                      <a class="page-link font-size-14" href="#">...</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14" href="#">66</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link font-size-14" href="#">67</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link border-left rounded-0 text-gray-5" href="#" aria-label="Next">
                        <i class="flaticon-right-thin-chevron font-size-10 font-weight-bold"></i>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <!-- End Tab Content -->
          </div>
          <!-- Slick Tab carousel -->
        </div>
      </div>
    </div>
  </div>
</main>
<!-- ========== END MAIN CONTENT ========== -->
