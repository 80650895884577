import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from "rxjs";
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { first } from 'rxjs/operators';
import { Role } from 'src/app/model/role.model';
import * as sha256 from 'crypto-js/sha256';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showLoginForm:boolean;
  ipAddress:any;
  email: string;
  password: any;
  userData:any;
  forgotPass:boolean;
  yourEmail: string;
  isAgent: boolean = false;
  isUser: boolean = true;
  private REST_API_LOGIN = `${environment.BASE_URL}/api/Account/Login`;
  private Forgot_Password = `${environment.BASE_URL}/api/Account/ForGotPassword`;
  returnUrl: string;
  private 
  constructor(private httpClient: HttpClient,  private router: Router,private authenticationService :AuthenticationService,private route: ActivatedRoute ) {
    if (this.authenticationService.currentUserValue) { 
      this.router.navigate(['/']);
  }

      this.showLoginForm = false;
      this.forgotPass = false;
  }


  ngOnInit(): void {
    this.getIP();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

   getIP()
  {
    /*this.headerService.getIPAddress().subscribe((res:any)=>{
      console.log("=====>IP", res);
      this.ipAddress=data.ip;
    });*/
    this.httpClient.get("https://api.ipify.org/?format=json").subscribe((res:any) =>{
      this.ipAddress = res.ip;
      console.log("=====>ip", this.ipAddress);
    })
  }

  showLogin=(e:Event)=>{
    this.showLoginForm = !this.showLoginForm
    this.forgotPass = false;
  }

  agentLogin(event){
    this.isAgent = true;
    this.isUser = false;
  }
  userLogin(){
    this.isUser = true;
    this.isAgent = false;
  }

  forgotPassword=(e:Event) =>{
    this.forgotPass = !this.forgotPass;
    this.showLoginForm = false;
  }

  recoverPassword(){
    let body = {
      "Email": this.yourEmail
    }
    return this.httpClient.get(this.Forgot_Password, {params:body}).subscribe((res:any)=>{
    });
  }
  refreshpage()
  {
    window.location.reload();
  }
  Login(){

    this.authenticationService.login(this.email, this.password,this.isAgent).pipe(first())
    .subscribe(
        data => {


      
            if(data.UserDetails.Role !== 5){
              this.showLoginForm = !this.showLoginForm;
            }
        // this.onLoginSuccess.emit();

    

          if(data.UserDetails){
            if(data.UserDetails.Role ==Role.Admin || data.UserDetails.Role ==Role.Distributor){
               this.router.navigate(['/dashboard/myprofile']);
            }
            else if (data.UserDetails.Role ==Role.Agent)
            {
               this.router.navigate(['/dashboard/buses']);
            }
          }
            // this.router.navigate([this.returnUrl]);
            // this.router.navigate(['/dashboard']);
        },
        error => {
            // this.error = error;
            // this.loading = false;
        });

    
    
}

 
    
  

}

